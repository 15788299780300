import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import * as IntegradorActions from "../actions/integrador.action";
import { IntegracaoService } from "../service/integracao.service";
import { ToastrFunctions } from "../util/toastr.functions";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class IntegradorEffects {
  constructor(
    private actions$: Actions,
    private integracaoService: IntegracaoService,
    private toastr: ToastrService
  ) {}

  loadIntegracao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegradorActions.loadIntegracao),
      mergeMap(({ sistemaId }) =>
        this.integracaoService.loadIntegration(sistemaId).pipe(
          map((integracao) =>
            IntegradorActions.loadIntegracaoSuccess({ integracao })
          ),
          catchError((error) => {
            // Check if the error status is 404
            if (error.status === 404) {
              // Show an info message with Toastr
              ToastrFunctions.showInfo(this.toastr, 'Integração não encontrada.');
            }
            return of(IntegradorActions.loadIntegracaoFailure({ error }));
          })
        )
      )
    )
  );
  

  // Add a new integration
  addIntegracao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegradorActions.addIntegracao),
      mergeMap(({ sistemaId }) =>
        this.integracaoService.createIntegration(sistemaId).pipe(
          tap((mensagem) => {
            // Show the informational message using ToastrFunctions
            ToastrFunctions.showInfo(this.toastr, mensagem);
          }),
          mergeMap((mensagem) => [
            // Dispatch the success action with the returned message
            IntegradorActions.addIntegracaoSuccess({ mensagem }),
            // Optionally reload the integration after the add operation
            IntegradorActions.loadIntegracao({ sistemaId }),
          ]),
          catchError((error) =>
            of(IntegradorActions.addIntegracaoFailure({ error }))
          )
        )
      )
    )
  );

  // Delete an integration
  deleteIntegracao$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegradorActions.deleteIntegracao),
      mergeMap(({ id }) =>
        this.integracaoService.deleteIntegration(id).pipe(
          tap((mensagem) => {
            // Show the informational message using ToastrFunctions
            ToastrFunctions.showInfo(this.toastr, mensagem);
          }),
          map((mensagem) =>
            IntegradorActions.deleteIntegracaoSuccess({ mensagem })
          ),
          catchError((error) =>
            of(IntegradorActions.deleteIntegracaoFailure({ error }))
          )
        )
      )
    )
  );

  // Toggle the status of an integration
  toggleIntegracaoStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegradorActions.toggleIntegracaoStatus),
      mergeMap(({ id }) =>
        this.integracaoService.toggleIntegrationStatus(id).pipe(
          tap((mensagem) => {
            // Show the informational message using ToastrFunctions
            ToastrFunctions.showInfo(this.toastr, mensagem);
          }),
          map((mensagem) =>
            IntegradorActions.toggleIntegracaoStatusSuccess({ id, mensagem })
          ),
          catchError((error) =>
            of(IntegradorActions.toggleIntegracaoStatusFailure({ error }))
          )
        )
      )
    )
  );
}
