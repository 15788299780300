import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import { Observable, catchError, throwError } from "rxjs";
import { Integracao } from "../model/integracao.model";

@Injectable({
  providedIn: "root",
})
export class IntegracaoService {
  private readonly API = `${environment.API}/api/v1/integrador`;

  constructor(
    private httpClient: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getToken() {
    return this.keycloakService.getToken();
  }

  private createHeaders(): HttpHeaders {
    try {
      const token = this.getToken();
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });
      return headers;
    } catch (error) {
      throw new Error(`Erro ao obter o token: ${error}`);
    }
  }  

  createIntegration(sistemaId: string): Observable<string> {
    const headers = this.createHeaders();
    return this.httpClient
      .post<string>(`${this.API}/criar-integracao?sistemaId=${sistemaId}`, null, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        catchError((error) =>
          throwError(() => new Error(`Erro ao criar integração: ${error}`))
        )
      );
  }

  loadIntegration(sistemaId: string): Observable<Integracao> {
    const headers = this.createHeaders();
    return this.httpClient
      .get<Integracao>(`${this.API}/carregar-integracao/${sistemaId}`, { headers })
      .pipe(catchError((error) => throwError(() => error)));
  }

  toggleIntegrationStatus(integrationId: number): Observable<string> {
    const headers = this.createHeaders();
    return this.httpClient
      .patch<string>(
        `${this.API}/atualizar-status-integracao/${integrationId}`,
        null,
        {
          headers,
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        catchError((error) =>
          throwError(() => new Error(`Erro ao alterar status da integração: ${error}`))
        )
      );
  }

  deleteIntegration(integrationId: number): Observable<string> {
    const headers = this.createHeaders();
    return this.httpClient
      .delete<string>(`${this.API}/deletar-integracao/${integrationId}`, {
        headers,
        responseType: 'text' as 'json',
      })
      .pipe(
        catchError((error) =>
          throwError(() => new Error(`Erro ao deletar integração: ${error}`))
        )
      );
  }
  
}
