<h1 mat-dialog-title class="dialog-title">
  Visualizar Perfil do Colaborador Interno Anvisa
</h1>
<div mat-dialog-content *ngIf="selectedInternalUser$ | async as selectedInternalUser">
  <div class="main-content">
    <div>
      <p class="section-title">Colaborador(a):</p>
      <p class="section-content">{{ selectedInternalUser.noPessoaFisica }}</p>
    </div>
    <div>
      <p class="section-title">CPF:</p>
      <p class="section-content">{{ maskCpf(selectedInternalUser.nuCpf) }}</p>
    </div>
    <div>
      <p class="section-title">Ativo:</p>
      <p class="section-content">{{ active ? 'Sim' : 'Não' }}</p>
    </div>
    <div>
      <p class="section-title">Perfis:</p>
      <ul class="perfis-list">
        <li *ngFor="let perfil of selectedInternalUser.perfisInterno">{{ normalizeString(perfil.noPerfil) }}</li>
      </ul>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button
    class="button input-button-white-custom dialog-button"
    mat-dialog-close>
    Fechar
  </button>
</div>
