<h1 mat-dialog-title class="dialog-title">
  Cadastro Anvisa - Dashboard
</h1>

<div mat-dialog-content class="dialog-content">
  <!-- Dashboard Data Display -->
  <ng-container *ngIf="(dashboardData$ | async) as dashboardData; else noData">
    <div class="dashboard-data">
      <!-- Total Updated Companies -->
      <div class="dashboard-row">
        <span class="bold-label">Total de CNPJs ligados ao GOV.BR:</span>
        <span>{{ dashboardData.totalUpdatedCompanies }}</span>
      </div>

      <!-- Divider -->
      <mat-divider></mat-divider>

      <!-- Total Legal Representatives -->
      <div class="dashboard-row">
        <span class="bold-label">Total de Responsáveis Legais logados com o GOV.BR:</span>
        <span>{{ dashboardData.totalLegalRepresentatives }}</span>
      </div>

      <!-- Divider -->
      <mat-divider></mat-divider>

      <!-- Total Physical Persons -->
      <div class="dashboard-row">
        <span class="bold-label">Total de CPFs logados com o GOV.BR:</span>
        <span>{{ dashboardData.totalPhysicalPersons }}</span>
      </div>

      <!-- Divider -->
      <mat-divider></mat-divider>

      <!-- Total Created Companies -->
      <div class="dashboard-row">
        <span class="bold-label">Total de Organizações Criadas pelo Cadastro Anvisa:</span>
        <span>{{ dashboardData.totalCreatedCompanies }}</span>
      </div>
    </div>
  </ng-container>

  <!-- Fallback content when data is unavailable -->
  <ng-template #noData>
    <div class="no-data">
      <p>Dados do dashboard indisponíveis.</p>
    </div>
  </ng-template>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button mat-button color="primary" mat-dialog-close class="close-button">
    Fechar
  </button>
</div>
