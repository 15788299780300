import { createReducer, on } from "@ngrx/store";
import * as SistemaActions from "../actions/sistema.actions";
import { SistemaState } from "../interfaces/sistema-state.interface";

export const initialState: SistemaState = {
  sistemas: [],
  sistemasUser: [],
  systemasByUsuarioRepresentanteId: {},
  allSistemas: [],
  error: null,
  isLoaded: {
    loadSistemas: true,
    loadSistemaByUser: true,
    loadSistemaByUsuarioRepresentanteId: true,
    loadAllSistemas: true,
  }
};

export const sistemaReducer = createReducer(
  initialState,
  on(SistemaActions.loadSistemas, state => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadSistemas: false }
  })),
  on(SistemaActions.loadSistemasSuccess, (state, { sistemas }) => ({
    ...state,
    sistemas,
    isLoaded: { ...state.isLoaded, loadSistemas: true }
  })),
  on(SistemaActions.loadSistemasFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadSistemas: true }
  })),

  on(SistemaActions.loadSistemaByUser, state => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadSistemaByUser: false }
  })),
  on(SistemaActions.loadSistemaByUserSuccess, (state, { sistemasUser }) => ({
    ...state,
    sistemasUser,
    isLoaded: { ...state.isLoaded, loadSistemaByUser: true }
  })),
  on(SistemaActions.loadSistemaByUserFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadSistemaByUser: true }
  })),

  on(SistemaActions.loadSistemaByUsuarioRepresentanteId, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadSistemaByUsuarioRepresentanteId: false },
    error: null,
  })),
  on(
    SistemaActions.loadSistemaByUsuarioRepresentanteIdSuccess,
    (state, { idUsuarioRepresentante, sistemas }) => ({
      ...state,
      systemasByUsuarioRepresentanteId: { ...state.systemasByUsuarioRepresentanteId, [idUsuarioRepresentante]: sistemas },
      isLoaded: { ...state.isLoaded, loadSistemaByUsuarioRepresentanteId: true },
    })
  ),
  on(SistemaActions.loadSistemaByUsuarioRepresentanteIdFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadSistemaByUsuarioRepresentanteId: true },
  })),

  on(SistemaActions.loadAllSistemas, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadAllSistemas: false },
  })),
  on(SistemaActions.loadAllSistemasSuccess, (state, { allSistemas }) => ({
    ...state,
    allSistemas,
    isLoaded: { ...state.isLoaded, loadAllSistemas: true },
  })),
  on(SistemaActions.loadAllSistemasFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, loadAllSistemas: true },
  })),

  on(SistemaActions.clearSistemaState, () => ({
    ...initialState,
    isLoaded: {
      loadSistemas: true,
      loadSistemaByUser: true,
      loadSistemaByUsuarioRepresentanteId:true
    }
  }))
);
