<h1 mat-dialog-title>Atribuição de Perfil para Colaborador</h1>
<div mat-dialog-content>
  <div
    class="card-custom"
    style="margin-left: 13px; margin-right: 13px; margin-bottom: 20px"
  >
    Colaborador(a): {{ noPessoaFisica }}<br />
    <!-- Check if CNPJ is not empty and display it; otherwise, display CNES -->
    CNPJ: {{ cnpj }}<br />
    Empresa: {{ empresa }}<br />
  </div>
  <div class="container">
    <div class="row line-bottom">
      <div style="width: 28%">
        <div style="font-size: 14px; margin-top: 10px">
          <strong>Ativo</strong>
        </div>
        <div class="column container-vertical-align">
          <div>
            <mat-slide-toggle
              id="activate-user"
              color="primary"
              [checked]="(selectedUser$ | async)?.stBloqueado === 'N'"
              (change)="ativaInativaColaborador($event)"
              [disabled]="!isPerfilGestorAnvisa && !isPerfilGestorCadastrosResponsavelLegal"
            ></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="column" style="width: 38%">
        <div style="font-size: 14px"><strong>Data de Inativação</strong></div>
        <div>
          <div style="position: relative; display: flex; align-items: center">
            <mat-form-field style="flex-grow: 1">
              <mat-label>Selecione uma Data</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [(ngModel)]="dtExpiracao"
              />
              <mat-hint *ngIf="!dtExpiracao">DD/MM/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <button
              mat-button
              (click)="dtExpiracao = null"
              aria-label="Limpar data"
              *ngIf="dtExpiracao"
              style="margin-left: 8px; margin-bottom: 22px"
            >
              <a href="javascript:void(0);">
                <img src="../../assets/img/icon_trash.png" border="0" />
              </a>
            </button>
          </div>
        </div>
      </div>

      <div class="column" style="width: 33%">
        <input
          id="update-user-inactivation-date"
          type="button"
          [value]="
            dtExpiracao
              ? 'Atualizar Data de Inativação'
              : 'Limpar Data de Inativação'
          "
          class="input-button-white-custom"
          style="float: left; margin-top: 30px"
          [disabled]="(selectedUser$ | async)?.dtExpiracao === undefined && dtExpiracao === null"
          (click)="atualizaDataExpiracao()"
        />
      </div>
    </div>
    <div>
      <div style="font-size: 14px"><strong>Sistemas / Perfis</strong></div>
    </div>
    <div class="row">
      <div class="column" style="width: 33%">
        <select
          [(ngModel)]="selectedSistemaId"
          class="select-custom"
          style="width: 220px; float: left"
          (change)="loadPerfis()"
        >
          <option value="">Selecione um Sistema</option>
          <option *ngFor="let item of sistemas$ | async" [value]="item.id">
            {{ item.sgSistema }}
          </option>
        </select>
      </div>
      <div class="column" style="width: 33%">
        <select
          id="select-perfil"
          [(ngModel)]="selectedPerfilId"
          class="select-custom"
          style="width: 220px; float: left"
          (change)="onSelectChange($event)"
          [disabled]="!selectedSistemaId"
        >
          <option value="">Selecione um Perfil</option>
          <option *ngFor="let item of perfis$ | async" [value]="item.id">
            {{ item.noPerfil }}
          </option>
        </select>
      </div>
      <div class="column" style="width: 33%">
        <input
          id="add-perfil-button"
          type="button"
          value="Adicionar Perfil"
          (click)="insereSistemaPerfisUsuario()"
          class="input-button-white-custom"
          style="float: left"
          [disabled]="selectedSistemaId === '' || selectedPerfilId === ''"
        />
      </div>
    </div>
    <div *ngIf="isGestorCadastroSelected">
      <div>
        <div style="font-size: 14px">
          <strong>Pessoas Jurídicas para o Gestor de Cadastro</strong>
        </div>
      </div>
      <div class="row">
        <div class="column" style="width: 66%">
          <mat-form-field class="custom-field" style="min-width: 500px">
            <mat-select [(ngModel)]="cnpjs" multiple>
              <mat-select-trigger aria-label="Selecione as pessoas jurídicas">
                {{
                  cnpjs.length === 0
                    ? "Nenhuma pessoa jurídica selecionada"
                    : cnpjs[0].razaoSocial
                }}
                <span *ngIf="cnpjs.length > 1">
                  (+{{ cnpjs.length - 1 }}
                  {{ cnpjs.length === 2 ? "outro" : "outros" }})
                </span>
              </mat-select-trigger>

              <!-- If profile is Constants.PERFIL_GESTOR_ANVISA -->
              <ng-container
                *ngIf="
                  (profileSelected$ | async) === Constants.PERFIL_GESTOR_ANVISA || (profileSelected$ | async) === Constants.PERFIL_ADMINISTRADOR
                "
              >
                <mat-option
                  *ngIf="selectedCompany$ | async as selectedCompany"
                  [value]="selectedCompany"
                >
                  {{ selectedCompany.razaoSocial }}
                </mat-option>
              </ng-container>

              <!-- Otherwise, show all companies -->
              <ng-container
                *ngIf="
                  (profileSelected$ | async) !== Constants.PERFIL_GESTOR_ANVISA && (profileSelected$ | async) !== Constants.PERFIL_ADMINISTRADOR
                "
              >
                <mat-option
                  *ngFor="
                    let pj of allCompanies$ | async;
                    trackBy: trackByCompanyFn
                  "
                  [value]="pj"
                >
                  {{ pj.razaoSocial }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
          <!-- Tree node template for leaf nodes -->
          <mat-tree-node
            *matTreeNodeDef="let node; when: !hasChild"
            matTreeNodePadding
          >
            <button mat-icon-button disabled>
              <!-- Visual cue for leaf nodes -->
            </button>
            <span>
              <img
                src="../../assets/img/icon_circle.png"
                style="margin-right: 5px"
              />
              {{ node.item }}</span
            >
            <button
              mat-button
              (click)="openDialogExclusao(node)"
              aria-label="Delete {{ node.item }}"
            >
              <a href="javascript:void(0);"
                ><img src="../../assets/img/icon_trash.png" border="0"
              /></a>
            </button>
          </mat-tree-node>

          <!-- Tree node template for expandable nodes -->
          <mat-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding
          >
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.item"
            >
              <mat-icon>{{
                treeControl.isExpanded(node) ? "expand_more" : "chevron_right"
              }}</mat-icon>
            </button>
            <span>{{ node.item }}</span>
            <button
              mat-button
              (click)="openDialogExclusao(node)"
              aria-label="Delete {{ node.item }}"
            >
              <a href="javascript:void(0);"
                ><img src="../../assets/img/icon_trash.png" border="0"
              /></a>
            </button>
          </mat-tree-node>
        </mat-tree>
      </div>
    </div>
  </div>
  <div class="container-buttons">
    <button mat-dialog-close class="button input-button-white-custom">
      Fechar
    </button>
  </div>
</div>
