import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject, takeUntil } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../components/dialog/dialog.component";
import { Store } from "@ngrx/store";
import { AppState } from "../interfaces/app-state.interface";
import * as SistemaActions from "../actions/sistema.actions";
import * as IntegradorActions from "../actions/integrador.action";
import { selectIsAdministrador } from "../selectors/auth.selectors";
import { Sistema } from "../model/sistema.model";
import { Integracao } from "../model/integracao.model";
import { selectIntegracao } from "../selectors/integrador.selectors";
import { selectAllSistemas } from "../selectors/sistema.selector";

@Component({
  selector: "app-integrador",
  templateUrl: "./integrador.component.html",
  styleUrls: ["./integrador.component.scss"],
})
export class IntegradorComponent implements OnInit, OnDestroy {
  isAdministrador = false;
  sistemas: Sistema[] = [];
  integracao: Integracao | null = null;
  selectedSistema: Sistema | null = null;
  displayedColumns: string[] = ["name", "actions"];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.checkUserProfile();
    this.observeSistemas();
    this.observeIntegration();
  }

  /**
   * Check if the user is an administrator and redirect if not.
   */
  private checkUserProfile(): void {
    this.store
      .select(selectIsAdministrador)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isAdministrador) => {
        this.isAdministrador = isAdministrador
        if (!this.isAdministrador) {
          this.router.navigate(["/colab-vinc-cnpj-cnes"]);
        } else {
          this.loadSistemas();
        }
      });
  }

  /**
   * Observe the list of systems.
   */
  private observeSistemas(): void {
    this.store
      .select(selectAllSistemas)
      .pipe(takeUntil(this.destroy$))
      .subscribe((sistemas) => {
        this.sistemas = sistemas;
      });
  }

  /**
   * Observe the current integration.
   */
  private observeIntegration(): void {
    this.store
      .select(selectIntegracao)
      .pipe(takeUntil(this.destroy$))
      .subscribe((integracao) => {
        this.integracao = integracao;
      });
  }

  /**
   * Load the list of systems.
   */
  private loadSistemas(): void {
    this.store.dispatch(SistemaActions.loadAllSistemas());
  }

  /**
   * Handle system selection and load its integration.
   * @param sistema Selected system
   */
  onSistemaSelected(sistema: Sistema): void {
    this.selectedSistema = sistema;
    if (this.selectedSistema) {
      this.store.dispatch(
        IntegradorActions.loadIntegracao({ sistemaId: this.selectedSistema.id })
      );
    }
  }

  /**
   * Add a new integration for the selected system.
   */
  handleIntegration(): void {
    const sistemaId = this.selectedSistema?.id;
    if (sistemaId) {
      this.store.dispatch(IntegradorActions.addIntegracao({ sistemaId }));
    }
  }

  /**
   * Open a dialog to confirm the deletion of an integration.
   * @param enterAnimationDuration Dialog enter animation duration
   * @param exitAnimationDuration Dialog exit animation duration
   * @param integracaoId ID of the integration to delete
   */
  openDialogExclusao(
    enterAnimationDuration: string,
    exitAnimationDuration: string,
    integracaoId: number
  ): void {
    const dialogDelete = this.dialog.open(DialogComponent, {
      width: "400px",
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogDelete.afterClosed().subscribe((result: any) => {
      if (result) {
        this.store.dispatch(
          IntegradorActions.deleteIntegracao({ id: integracaoId })
        );
      }
    });
  }

  /**
   * Toggle the status of the current integration.
   */
  onToggleIntegrationStatus(): void {
    if (!this.integracao) {
      this.toastr.error("Integração não encontrada.");
      return;
    }

    this.store.dispatch(
      IntegradorActions.toggleIntegracaoStatus({ id: this.integracao.id })
    );
  }

  trackBySistema(index: number, sistema: Sistema): string {
    return sistema?.id; // Replace `id` with the unique property of your Sistema object
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
