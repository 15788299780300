<h2 mat-dialog-title>Acesso ao Cadastro Anvisa</h2>
<mat-dialog-content>   
    <p>
      Se você não possui o perfil de consulta do Cadastro Anvisa ou se possui, e não consegue acessar, entre em contato com a CGTAI – <a href="mailto:cgtai@anvisa.gov.br">cgtai@anvisa.gov.br</a>.
    </p>
    <br/>
    <p>
      Estamos à disposição para ajudar!
    </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close cdkFocusInitial>Ok</button>
</mat-dialog-actions>
