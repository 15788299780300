import { createAction, props } from "@ngrx/store";
import { Integracao } from "../model/integracao.model";

export const loadIntegracao = createAction(
  "[Integrador] Load Integracao",
  props<{ sistemaId: string }>()
);
export const loadIntegracaoSuccess = createAction(
  "[Integrador] Load Integracao Success",
  props<{ integracao: Integracao | null }>()
);
export const loadIntegracaoFailure = createAction(
  "[Integrador] Load Integracao Failure",
  props<{ error: any }>()
);

export const addIntegracao = createAction(
  "[Integrador] Add Integracao",
  props<{ sistemaId: string }>()
);
export const addIntegracaoSuccess = createAction(
  "[Integrador] Add Integracao Success",
  props<{ mensagem: string }>()
);
export const addIntegracaoFailure = createAction(
  "[Integrador] Add Integracao Failure",
  props<{ error: any }>()
);

export const deleteIntegracao = createAction(
  "[Integrador] Delete Integracao",
  props<{ id: number }>()
);
export const deleteIntegracaoSuccess = createAction(
  "[Integrador] Delete Integracao Success",
  props<{ mensagem: string }>()
);
export const deleteIntegracaoFailure = createAction(
  "[Integrador] Delete Integracao Failure",
  props<{ error: any }>()
);

export const toggleIntegracaoStatus = createAction(
  "[Integrador] Toggle Integracao Status",
  props<{ id: number }>()
);
export const toggleIntegracaoStatusSuccess = createAction(
  "[Integrador] Toggle Integracao Status Success",
  props<{ id: number, mensagem: string }>()
);
export const toggleIntegracaoStatusFailure = createAction(
  "[Integrador] Toggle Integracao Status Failure",
  props<{ error: any }>()
);

export const clearIntegradorState = createAction("[Integrador] Clear State");
