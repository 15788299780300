<div style="width: 100%; height: 32px; margin-bottom: 20px">
  <div style="float: right">
    <a routerLink="/integrador"
      ><img
        style="float: left"
        src="../../assets/img/icon_integration.png"
        border="0"
    /></a>
    <input
      routerLink="/log"
      type="button"
      value="Auditoria"
      class="input-button-white-custom"
      style="float: right; margin-left: 14px"
    />
    <input
      type="button"
      value="Dashboard"
      class="input-button-white-custom"
      style="float: right; margin-left: 14px"
      (click)="openDashboardDialog()"
    />
  </div>
</div>

<div style="width: 100%; margin-top: 32px; margin-bottom: 20px">
  <select
    class="select-custom"
    [(ngModel)]="selectedCpfOrNomeOrEmail"
    #selectElement
    (change)="changePlaceholder(selectElement.value)"
    style="min-width: 284px; float: left"
  >
    <option value="pesquisarCpf" selected>Pesquisar por CPF</option>
    <option value="pesquisarNome">Pesquisar por Nome</option>
    <option value="pesquisarEmail">Pesquisar por E-mail</option>
  </select>
  <div
    class="input-container"
    style="margin-left: 20px; float: left; width: 250px"
  >
    <input
      type="text"
      #inputSearchValue
      [placeholder]="placeholderText"
      [(ngModel)]="searchValue"
      (input)="onInputChange()"
      [ngClass]="{ 'error-highlight': hasInputError }"
    />
  </div>
  <input
    type="button"
    value="Pesquisar"
    (click)="doSearch()"
    class="input-button-custom"
    style="margin-left: 20px"
  />
  <input
    type="button"
    value="Limpar"
    (click)="reset()"
    class="input-button-white-custom"
    style="margin-left: 20px"
  />
</div>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="nome">
    <th mat-header-cell *matHeaderCellDef style="width: 25%">Nome</th>
    <td mat-cell *matCellDef="let element" style="width: 25%" class="no-hover">
      {{ element.noPessoaFisica }}
    </td>
  </ng-container>

  <ng-container matColumnDef="ativo">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Ativo</th>
    <td mat-cell *matCellDef="let element" style="width: 10%">
      <img
        *ngIf="element.stBloqueado == 'N'"
        src="../../assets/img/icon_active.png"
        border="0"
      />
      <img
        *ngIf="element.stBloqueado == 'S'"
        src="../../assets/img/icon_inactive.png"
        border="0"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">E-mail</th>
    <td mat-cell *matCellDef="let element" style="width: 20%">
      {{ element.dsEmail }}
    </td>
  </ng-container>

  <ng-container matColumnDef="perfis">
    <th mat-header-cell *matHeaderCellDef style="width: 20%">Perfis</th>
    <td mat-cell *matCellDef="let element" style="width: 20%">
      <ul style="width: 100%">
        <li *ngFor="let item of element.perfis.split(',')">{{ normalizeString(item) }}</li>
      </ul>
    </td>
  </ng-container>

  <ng-container matColumnDef="visualizar">
    <th mat-header-cell *matHeaderCellDef style="width: 10%">Visualizar</th>
    <td mat-cell *matCellDef="let element" style="width: 10%">
      <div *ngIf="isPerfilGestorAnvisa || isAdministrador || isPerfilGestorAnvisaConsulta" style="padding-left: 15px;">
        <a
          (click)="
            openDialogAddPerfilColab(
              '0ms',
              '0ms',
              element.nuCpf,
              element.username
            )
          "
          title="Visualizar"
        >
          <img src="../../assets/img/icon_view.png" border="0" />
        </a>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator
  #paginatorPageSize
  [pageSizeOptions]="pageSizes"
  [length]="totalElements"
  (page)="nextPage($event)"
  showFirstLastButtons
></mat-paginator>
