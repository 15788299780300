import { createSelector, createFeatureSelector } from "@ngrx/store";
import { IntegradorState } from "../interfaces/integrador-state.interface";
import { createIsLoadedSelector } from "./genericselector.interface";

export const selectIntegradorState = createFeatureSelector<IntegradorState>("integrador");

export const selectIntegracao = createSelector(
  selectIntegradorState,
  (state) => state.integracao
);

export const selectIntegradorError = createSelector(
  selectIntegradorState,
  (state) => state.error
);


export const getIntegradorIsLoaded = createIsLoadedSelector(selectIntegradorState);
