import { createSelector, createFeatureSelector } from '@ngrx/store';
import { createIsLoadedSelector } from './genericselector.interface';
import { SistemaState } from '../interfaces/sistema-state.interface';

export const selectSistemaFeature = createFeatureSelector<SistemaState>('sistemas');

export const selectSistemas = createSelector(
  selectSistemaFeature,
  (state: SistemaState) => state.sistemas
);

export const selectAllSistemasByUser = createSelector(
  selectSistemaFeature,
  (state: SistemaState) => state.sistemasUser
);

export const selectAllSistemaByUsuarioRepresentanteId = (usuarioRepresentante: string) =>  createSelector(
  selectSistemaFeature,
  (state: SistemaState) => state.systemasByUsuarioRepresentanteId[usuarioRepresentante]
);

export const selectSistemaError = createSelector(
  selectSistemaFeature,
  (state: SistemaState) => state.error
);

export const selectAllSistemas = createSelector(
  selectSistemaFeature,
  (state) => state.allSistemas
);

export const getSistemaIsLoaded =  createIsLoadedSelector(selectSistemaFeature);
