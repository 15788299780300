import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import * as LogActions from "../actions/log.action";
import { LogService } from "../service/log.service";

@Injectable()
export class LogEffects {
  constructor(private actions$: Actions, private logService: LogService) {}

  chooseLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LogActions.chooseLogs),
      mergeMap((action) =>
        this.logService
          .getLogsByAutorNomeAndInterval(
            action.noPessoaFisica,
            action.joinPessoaJuridica,
            action.idPessoaJuridica,
            action.intervalo,
            action.page,
            action.size
          )
          .pipe(
            map((logs) => LogActions.chooseLogsSuccess({ logs })),
            catchError(() =>
              of(
                LogActions.chooseLogsFailure({ error: "Failed to fetch logs" })
              )
            )
          )
      )
    )
  );
}
