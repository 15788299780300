<!-- Main Card for Managing Integrations -->
<mat-card class="main-card">
  <mat-card-header>
    <mat-card-title>Gerenciar Integrações</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <!-- Dropdown to Select Sistema -->
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Selecione o Sistema</mat-label>
      <mat-select
        [(ngModel)]="selectedSistema"
        (selectionChange)="onSistemaSelected($event.value)"
      >
        <mat-option
          *ngFor="let sistema of sistemas; trackBy: trackBySistema"
          [value]="sistema"
        >
          {{ sistema.sgSistema }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <!-- Add Integration Button -->
    <button
      mat-raised-button
      color="primary"
      (click)="handleIntegration()"
      matTooltip="Adicionar nova integração"
      aria-label="Adicionar nova integração"
      [disabled]="!selectedSistema || integracao"
    >
      Adicionar Integração
    </button>
  </mat-card-actions>
</mat-card>

<!-- Integration Details Card -->
<mat-card *ngIf="selectedSistema" class="integration-card">
  <section *ngIf="integracao; else noIntegracao">
    <!-- Card Header -->
    <mat-card-header>
      <mat-card-title class="integration-title">
        Integração {{ integracao.sgSistema }} - {{ integracao.noSistema }}
      </mat-card-title>
    </mat-card-header>

    <!-- Card Content -->
    <mat-card-content>
      <div fxLayout="row" fxLayoutAlign="start center" class="info-row">
        <mat-icon color="primary" class="icon">update</mat-icon>
        <strong class="info-label">Última Atualização:&nbsp;</strong>
        <span>{{ integracao.dataAtualizacao | date: "dd/MM/yyyy HH:mm" }}</span>
      </div>
    </mat-card-content>

    <!-- Card Actions -->
    <mat-card-actions class="actions-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <!-- Slide Toggle for Integration Status -->
      <mat-slide-toggle
        color="primary"
        [checked]="integracao.stBloqueado === 'N'"
        (change)="onToggleIntegrationStatus()"
        matTooltip="Clique para alterar o status da integração"
        aria-label="Alterar status da integração"
      >
        {{ integracao.stBloqueado === 'N' ? 'Ativada' : 'Bloqueada' }}
      </mat-slide-toggle>

      <!-- Delete Integration Button -->
      <button
        mat-icon-button
        color="primary"
        (click)="openDialogExclusao('0ms', '0ms', integracao.id)"
        matTooltip="Excluir integração"
        aria-label="Excluir integração"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-actions>
  </section>

  <!-- No Integration Message -->
  <ng-template #noIntegracao>
    <p class="no-integration-message">
      Nenhuma integração disponível para este sistema.
    </p>
  </ng-template>
</mat-card>
