import { createAction, props } from "@ngrx/store";
import { Sistema } from "../model/sistema.model";

export const loadSistemas = createAction("[Sistema] Load Sistemas");
export const loadSistemasSuccess = createAction(
  "[Sistema] Load Sistemas Success",
  props<{ sistemas: Sistema[] }>()
);
export const loadSistemasFailure = createAction(
  "[Sistema] Load Sistemas Failure",
  props<{ error: any }>()
);

export const loadSistemaByUser = createAction(
  "[Sistema] Load Sistema by User",
  props<{ idUsuarioRepresentante: string }>()
);
export const loadSistemaByUserSuccess = createAction(
  "[Sistema] Load Sistema by User Success",
  props<{ sistemasUser: Sistema[] }>()
);
export const loadSistemaByUserFailure = createAction(
  "[Sistema] Load Sistema by User Failure",
  props<{ error: any }>()
);

export const loadSistemaByUsuarioRepresentanteId = createAction(
  "[Sistema] Load Sistema by Usuario Representante ID",
  props<{ idUsuarioRepresentante: string }>()
);
export const loadSistemaByUsuarioRepresentanteIdSuccess = createAction(
  "[Sistema] Load Sistema by Usuario Representante ID Success",
  props<{ idUsuarioRepresentante: string; sistemas: Sistema[] }>()
);
export const loadSistemaByUsuarioRepresentanteIdFailure = createAction(
  "[Sistema] Load Sistema by Usuario Representante ID Failure",
  props<{ error: any }>()
);

export const loadAllSistemas = createAction('[Sistema] Load All Sistemas');
export const loadAllSistemasSuccess = createAction(
  '[Sistema] Load All Sistemas Success',
  props<{ allSistemas: Sistema[] }>()
);
export const loadAllSistemasFailure = createAction(
  '[Sistema] Load All Sistemas Failure',
  props<{ error: any }>()
);

export const clearSistemaState = createAction("[Sistema] Clear State");
