import { createReducer, on } from "@ngrx/store";
import * as IntegradorActions from "../actions/integrador.action";
import { IntegradorState } from "../interfaces/integrador-state.interface";

export const initialState: IntegradorState = {
  integracao: null,
  error: null,
  isLoaded: {
    loadIntegracao: true,
    addIntegracao: true,
    deleteIntegracao: true,
    toggleIntegracaoStatus: true,
  },
};

export const integradorReducer = createReducer(
  initialState,

  // Load a single integration
  on(IntegradorActions.loadIntegracao, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadIntegracao: false },
  })),
  on(IntegradorActions.loadIntegracaoSuccess, (state, { integracao }) => ({
    ...state,
    integracao,
    isLoaded: { ...state.isLoaded, loadIntegracao: true },
  })),
  on(IntegradorActions.loadIntegracaoFailure, (state, { error }) => ({
    ...state,
    integracao: null,
    error,
    isLoaded: { ...state.isLoaded, loadIntegracao: true },
  })),

  // Add a new integration
  on(IntegradorActions.addIntegracao, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, addIntegracao: false },
  })),
  on(IntegradorActions.addIntegracaoSuccess, (state, ) => ({
    ...state,
    isLoaded: { ...state.isLoaded, addIntegracao: true },
  })),
  on(IntegradorActions.addIntegracaoFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, addIntegracao: true },
  })),

  // Delete the current integration
  on(IntegradorActions.deleteIntegracao, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, deleteIntegracao: false },
  })),
  on(IntegradorActions.deleteIntegracaoSuccess, (state) => ({
    ...state,
    integracao: null,
    isLoaded: { ...state.isLoaded, deleteIntegracao: true },
  })),
  on(IntegradorActions.deleteIntegracaoFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, deleteIntegracao: true },
  })),

  // Toggle the status of the current integration
  on(IntegradorActions.toggleIntegracaoStatus, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, toggleIntegracaoStatus: false },
  })),
  on(
    IntegradorActions.toggleIntegracaoStatusSuccess,
    (state, { id }) => ({
      ...state,
      integracao: state.integracao && state.integracao.id === id
        ? {
            ...state.integracao,
            // Toggle the current status between 'S' (blocked) and 'N' (unblocked)
            stBloqueado: state.integracao.stBloqueado === 'S' ? 'N' : 'S',
          }
        : state.integracao,
      isLoaded: { ...state.isLoaded, toggleIntegracaoStatus: true },
    })
  ),
  
  on(IntegradorActions.toggleIntegracaoStatusFailure, (state, { error }) => ({
    ...state,
    error,
    isLoaded: { ...state.isLoaded, toggleIntegracaoStatus: true },
  })),

  // Clear the state
  on(IntegradorActions.clearIntegradorState, () => ({
    ...initialState,
  }))
);
